<template>
  <div>
    <b-card no-body>
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- From Date Fiter -->
          <b-col cols="12" md="4" lg="3" sm="6" class="mb-2 ipad">
            <b-form-group :label="$t('From date')" label-for="fromDate">
              <flat-pickr
                id="fromDate"
                v-model="search.name"
                class="form-control"
                placeholder="YYYY-MM-DD"
              />
              <div style="margin: 8px 0px; color: red" v-if="isFromDate">
                The field is require
              </div>
            </b-form-group>
          </b-col>
          <!-- To Date Filter -->
          <b-col cols="12" md="4" lg="3" sm="6" class="mb-2 ipad">
            <b-form-group :label="$t('To date')" label-for="toDate">
              <flat-pickr
                id="toDate"
                v-model="search.sport_type_id"
                class="form-control"
                placeholder="YYYY-MM-DD"
              />
              <div style="margin: 8px 0px; color: red" v-if="isToDate">
                The field is require
              </div>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row cols="12" class="d-flex align-items-end justify-content-end">
          <b-col
            cols="12"
            md="4"
            class="d-flex align-items-end justify-content-end"
          >
            <b-button variant="outline-primary" @click="resetFilter">
              Reset
            </b-button>
            <b-button variant="primary" class="ml-2" @click="searchFilter">
              Search
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <b-card no-body>
      <b-table-simple
        responsive
        bordered
        table-class="table-narrow table-white-space table-highlight"
        class="mb-2"
      >
        <b-tbody>
          <b-tr>
            <b-td style="vertical-align: top">
              <div style="margin: 8px">
                Sum: {{ sumTotal ? sumTotal/1000 : 0 }}
              </div>
              <b-table
                ref="refSportTeamListTable"
                class="
                  position-relative
                  table-white-space
                  mb-0
                  max-height-table
                "
                sticky-header
                head-variant="light"
                :items="resultData"
                responsive
                :fields="tableColumns"
                primary-key="index"
                :sort-by.sync="sortBy"
                show-empty
                empty-text="No matching records found"
                :sort-desc.sync="isSortDirDesc"
              >
                <template #cell(index)="data">
                  {{ perPage * (currentPage - 1) + data.index + 1 }}
                </template>
                <template #cell(date)="data">
                  <b-link class="cursor-pointer text-primary">
                    {{ data.item.date }}
                  </b-link>
                </template>
                <template #cell(Revenues)="data">
<!--                  <b-link class="cursor-pointer text-primary">-->
<!--                    {{ numberFormat(data.item.number / 1000) }}-->
<!--                  </b-link>-->
                  <span v-if="data.item.number > 0" class="text-primary">
                    {{ numberFormat(data.item.number / 1000) }}
                  </span>
                  <span v-if="data.item.number <= 0" class="text-danger">
                    {{ numberFormat(data.item.number / 1000) }}
                  </span>
                </template>
                <!-- <template #cell(quantityTransaction)="data">
                  {{ data.item.quantityTransaction }}
                </template> -->
              </b-table>
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>

      <div class="p-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-start
            "
          >
            <span class="text-muted"
              >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span
            >
          </b-col>
          <!-- Pagination -->
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BButton,
  BModal,
  VBModal,
  BCard,
  BRow,
  BCol,
  BFormInput,
  BTable,
  BTr,
  BTh,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BFormGroup,
  BImg,
  // BFormTextarea,
  // BBadge,
  BTableSimple,
  // BTr,
  // BTh,
  // BInputGroup,
  BTd,
  BTbody,
  BFormDatepicker,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import { ref, watch, computed, onUnmounted } from "@vue/composition-api";
import { formatDateTime, numberFormat } from "@core/utils/filter";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import store from "@/store";
import baseUrl from "@/libs/base-url";
// import sportsStoreModule from "@/views/sports/sportsStoreModule";
import AddSportsTeamModal from "@/views/sports/AddSportsTeam.vue";
import marketingStoreModule from "@/views/marketing/marketingStoreModule";
import UpdateSportTeamModal from "@/views/sports/UpdateSportsTeam.vue";
import flatPickr from "vue-flatpickr-component";
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
import moment from "moment";

export default {
  components: {
    // AddsportTeamModal,
    // EditsportTeamModal,
    AddSportsTeamModal,
    UpdateSportTeamModal,
    BButton,
    BFormGroup,
    BModal,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    // BTr,
    // BTh,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    BImg,
    BTableSimple,
    BTr,
    BTd,
    BTh,
    BTbody,
    flatPickr,
    BCardActions,
    BFormDatepicker,
    // BInputGroup,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  props: {
    userData: {
      type: Object,
    },
  },
  data() {
    return {
      sportTeamDetail: null,
    };
  },
  methods: {
    // showAddSportTeamModal() {
    //   this.$bvModal.show("modal-add-sports-team");
    // },
    // showEditSportTeamModal(data) {
    //   this.sportTeamDetail = data;
    //   this.$bvModal.show("modal-edit-sports-team");
    // },
  },
  setup(props) {
    if (!store.hasModule("marketing"))
      store.registerModule("marketing", marketingStoreModule);
    // onUnmounted(() => {
    //   if (store.hasModule("marketing")) store.unregisterModule("marketing");
    // });

    const currentDate = new Date();
    const sevenDaysAgo = new Date(currentDate);
    sevenDaysAgo.setDate(currentDate.getDate() - 30);


    const search = ref({
      name: sevenDaysAgo,
      sport_type_id: currentDate,
    });
    const sportTypeId = ref([]);
    const sportTypeIdFilter = ref(currentDate);
    const nameFilter = ref(sevenDaysAgo);

    const perPage = ref(25);
    const totalSportTeam = ref(0);
    const currentPage = ref(1);
    const perPageOptions = [10, 25, 50, 100];
    const sortBy = ref("id");
    const isSortDirDesc = ref(true);

    const refSportTeamListTable = ref(null);

    const tableColumns = [{ key: "index", label: "#" }, "date", "Revenues"];

    const refetchData = () => {
      refSportTeamListTable.value.refresh();
    };

    watch([currentPage, nameFilter, sportTypeIdFilter], () => {
      refetchData();
    });
    const sumTotal = ref("");
    const formattedNumber = (number) => {
      if (typeof number !== "undefined") {
        return number.toLocaleString("en-US");
      }
    };

    const dataMeta = computed(() => {
      const localItemsCount = refSportTeamListTable.value
        ? refSportTeamListTable.value.localItems.length
        : 0;
      return {
        from:
          perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
        to: perPage.value * (currentPage.value - 1) + localItemsCount,
        of: totalSportTeam.value,
      };
    });

    const statusOptions = [
      { label: "Active", value: 1 },
      { label: "inActive", value: 2 },
    ];

    const resolveStatus = (status) => {
      if (status == 1) return { label: "Active", variant: "primary" };
      if (status == 2) return { label: "inActive", variant: "danger" };
      return { label: "Pending", variant: "secondary" };
    };
    const isFromDate = ref(false);
    const isToDate = ref(false);
    const resetFilter = () => {
      search.value.name = "";
      search.value.sport_type_id = "";
      nameFilter.value = null;
      sportTypeIdFilter.value = null;
    };

    const searchFilter = () => {
      if (search.value.name == "") {
        isFromDate.value = true;
      }
      if (search.value.sport_type_id == "") {
        isToDate.value = true;
      }

      if (search.value.name !== "" && search.value.sport_type_id !== "") {
        isFromDate.value = false;
        isToDate.value = false;
        nameFilter.value = search.value.name;
        sportTypeIdFilter.value = search.value.sport_type_id;
      }
      sportTeamLists();
    };
    const revenuesData = ref({});
    const sumData = ref();
    const resultData = ref();
    const result = ref([]);
    const sportTeamLists = (ctx, callback) => {
      store
        .dispatch("marketing/fetchReportAgency", {
          agency_id: props.userData?.id,
          fromdate: moment(nameFilter.value).format('YYYY-MM-DD'),
          todate: moment(sportTypeIdFilter.value).format('YYYY-MM-DD'),
        })
        .then((response) => {
          revenuesData.value = response.data.data?.revenues;
          if (revenuesData.value) {
            resultData.value = Object.entries(revenuesData.value).map(
                ([date, number]) => ({
                  date,
                  number,
                })
            );
          }
          sumTotal.value = response.data.data?.sum;
        });
    };
    sportTeamLists();

    return {
      resultData,
      revenuesData,
      tableColumns,
      perPage,
      currentPage,
      totalSportTeam,
      dataMeta,
      perPageOptions,
      sortBy,
      isSortDirDesc,
      refSportTeamListTable,
      // refWithdrawalTable,
      sportTeamLists,
      // withdrawalTable,
      refetchData,
      nameFilter,
      search,
      resetFilter,
      sportTypeIdFilter,
      resolveStatus,
      statusOptions,
      formatDateTime,
      searchFilter,
      baseUrl,
      sportTypeId,
      numberFormat,
      sumTotal,
      // sumWithdrawal,
      isFromDate,
      isToDate,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
